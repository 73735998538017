<!-- TODO: refactor to vue3 -->
<script setup>
import {ChevronLeft, FolderDot, Eye, Trash2, Pencil, SquareDashedMousePointer, Share} from 'lucide-vue-next'

import NewLayerDialog from './Layers/NewLayerDialog.vue'
import ReportDataList from './ReportDataList.vue'

import {Badge} from '@/components/ui/badge'
import {Button} from '@/components/ui/button'
import {ImagesList} from '@/components/ui/images'
import {DialogConfirm} from '@/components/dialogs'
import {Point, LineString, Polygon} from '@/components/icons'
import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar'
</script>

<template>
  <div class="w-100" v-if="feature" :key="featureCode">
    <div class="flex p-1 items-center">
      <div class="basis-1/2 flex items-center">
        <Button v-if="$route.name === 'FeatureEdit'" size="sm" variant="ghost" class="w-8 h-8 rounded-full p-0" @click="back">
          <ChevronLeft class="text-gray-500" />
        </Button>
      </div>

      <div class="basis-1/2 flex items-center justify-end">
        <DialogConfirm
          ref="dialogDeleteConfirm"
          @confirm="deleteReport"
          title="Delete report"
          description="Are you sure you want to delete this report?"
        >
          <Button @click="focusOnFeature" size="sm" variant="ghost" class="w-8 h-8 p-0 rounded-full">
            <Trash2 class="text-red-400 w-4 h-4" />
          </Button>
        </DialogConfirm>
        <Button @click="showLeftDialog" size="sm" variant="ghost" class="w-8 h-8 p-0 rounded-full">
          <Pencil class="text-gray-500 w-4 h-4" />
        </Button>
        <Button @click="focusOnFeature" size="sm" variant="ghost" class="w-8 h-8 p-0 rounded-full">
          <Eye class="text-gray-500 w-4 h-4" />
        </Button>
        <Button @click="copyUrl" size="sm" variant="ghost" class="w-8 h-8 p-0 rounded-full">
          <Share class="text-gray-500 w-4 h-4" />
        </Button>
      </div>
    </div>

    <div v-if="feature.properties" class="px-3">
      <div class="flex items-center mb-2">
        <FolderDot class="mr-0.5 w-4 h-4 text-gray-500" />
        <span class="text-gray-500">{{ projectName }}</span>
      </div>

      <div class="mb-3">
        <div class="flex items-center ">
          <div class="mr-2 w-8 h-8 rounded-full shadow flex items-center justify-center" :style="{'background-color': feature.properties.color}">
            <div v-if="feature.properties?.s3_icon">
              <img class="object-contain w-5 h-5" :src="feature.properties?.s3_icon" alt="">
            </div>
            <div v-else>
              <Point v-if="iconName === 'point'" class="fill-white" />
              <LineString v-if="iconName === 'linestring'" class="fill-white" />
              <Polygon v-if="iconName === 'polygon'" class="fill-white" />
              <Polygon v-if="iconName === 'geometrycollection'" class="fill-white" />
              <Polygon v-if="iconName === 'multipolygon'" class="fill-white" />
            </div>
          </div>
          <h3 class="font-bold text-lg">{{ feature.properties.name }}</h3>
        </div>
      </div>
      <p v-if="feature.properties.description">
        {{ feature.properties.description }}
      </p>


      <div v-if="feature.properties.shared" class="mb-3">
        <div class="flex items-center">
          <Badge variant="secondary" class="text-xs">Shared</Badge>
        </div>
      </div>

      <ul class="text-gray-500 mb-4">
        <li>Created: {{ createdAt }}</li>
        <li>Synced: {{ syncedAt }}</li>
        <li class="mb-1" v-if="feature.properties.updated_at">Updated: {{ updatedAt }}</li>
        <!-- <li class="mt-1">Visible: {{ feature.properties.shared ? 'For all in project' : 'Only for me' }}</li> -->
        <li>Geometry: {{ feature.geometry.type }}</li>
        <li v-if="areaSize">Size in km²: {{ areaSize.skm }} km²</li>
        <li v-if="areaSize">Size in ha: {{ areaSize.ha }} ha</li>

        <div class="flex justify-between items-start mt-2">
          <div class="flex justify-start items-center">
            <Avatar class="w-6 h-6">
              <AvatarImage
                v-if="feature.properties.member_avatar_url"
                :src="feature.properties.member_avatar_url"
                :alt="feature.properties.member_display_name"
                :key="feature.properties.code"
              />
              <AvatarFallback></AvatarFallback>
            </Avatar>
            <span class="ml-2">{{ feature.properties.member_display_name }}</span>
          </div>
        </div>
      </ul>

      <div class="mb-1" v-if="feature.geometry.type === 'Polygon'">
        <Button size="sm" variant="secondary" @click="pairToProject">
          <SquareDashedMousePointer class="text-gray-600 w-4 h-4 mr-1" />
          Use as project boundary
        </Button>
      </div>

      <div class="mb-4" v-if="layers && feature.geometry.type === 'Polygon'">
        <div class="flex items-center justify-between">
          <h3 class="font-bold text-base">Layers</h3>
          <NewLayerDialog :feature="feature" v-if="numberOfVertexes <= MAX_NUMBER_OF_VERTEXES" />
        </div>

        <div v-if="numberOfVertexes > MAX_NUMBER_OF_VERTEXES" class="my-2 border border-gray-200 rounded p-2">
          <p class="text-gray-500 text-sm mb-1">
            Polygon has too many vertexes. Simplify it first.
            Found {{ numberOfVertexes }} vertexes, max is {{ MAX_NUMBER_OF_VERTEXES }}.
          </p>
          <Button size="sm" variant="secondary" @click="simplifyGeometry(feature.geometry)">Simplify</Button>
        </div>

        <div>
          <LayersList :layers="layers" :feature="feature" />
        </div>
      </div>

      <div class="mb-4" v-if="feature.properties?.geofence">
        <div>
          <h3 class="text-base font-bold flex">Virtual Geofence</h3>
          <ul class="text-gray-500">
            <li>Events: <span class="uppercase">{{ feature.properties.geofence_events.join(', ') }}</span></li>
            <li>Is user prompted: {{ feature.properties.geofence_prompt ? 'Yes' : 'No' }}</li>
          </ul>
          <div class=mt-1>
            <GeofenceEventList v-if="geofenceEvents?.length > 0" :events="geofenceEvents" />
            <div v-else>No any events</div>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <h3 class="mb-1 text-base font-bold">Collected data</h3>
        <ReportDataList v-if="feature.properties.data?.length > 0" :data="feature.properties.data" />
        <div v-else>No collected data</div>
      </div>

      <div v-if="feature.properties?.resources" class="mb-4">
        <h3 class="text-base font-bold mb-1">Images ({{resources.fullsizes.length}})</h3>
        <ImagesList v-if="resources.fullsizes.length" :images="resources" />
        <div v-else>No images</div>
      </div>

      <div class="mb-4">
        <notes :feature-code="feature.properties.code" :key="feature.properties.code"/>
      </div>

      <div v-if="feature.geometry.type === 'Polygon' && isSkysatAccess" class="mb-4">
        <h3 class="text-base font-bold mb-2">Planet Search</h3>
        <PlanetSearch :feature="feature" />
      </div>

      <div class="mb-4">
        <div>
          <div class="flex items-center justify-start">
            <h3 class="mb-1 text-base font-bold">Weather</h3>
          </div>
          <Weather :geometry="feature.geometry" />
        </div>
      </div>

      <div class="mb-4 text-gray-400">
        <h3 class="mb-0 font-bold text-base">Other</h3>
        <div class="mb-2">ID: {{ feature.properties.code }}</div>

        <Button size="sm" variant="secondary" @click="exportGeojson">Export Geojson</Button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import {coordEach} from '@turf/meta'
  import { bbox, area, simplify } from '@turf/turf'
  import {formatTime} from '@/utils/datetime'

  import Notes from './Notes.vue'
  import PlanetSearch from './PlanetSearch.vue'
  import POrder from '@/components/orders/Order.vue'
  import LayersList from './Layers/LayersList.vue'
  import GeofenceEventList from './GeofenceEventList.vue'
  import Weather from './Weather.vue'
  import { API, HOST } from '@/utils/http'
  import {useToast} from '@/components/ui/toast/use-toast'

  const { toast } = useToast()

  const MAX_NUMBER_OF_VERTEXES = 1500

  export default {
    components: {
      LayersList,
      POrder,
      Notes,
      PlanetSearch
    },
    data () {
      return {
        subtypeToIcon: {
          'report': 'point',
          'happiness': 'happiness',
          'catch': 'catch',
          'catch-iuu': 'catch-iuu',
        },
        geofenceEvents: null
      }
    },
    computed: {
      ...mapGetters({
        projects: 'project/getProjects',
        feature: 'geo/getCurrentFeature',
        planetFeaturesEnabled: 'geo/getPlanetFeaturesEnabled',
        settings: 'user/getUserSettings',
        map: 'geo/getMap',
        layers: 'planet/getLayers',
        isSkysatAccess: 'geo/getIsSkysatAccess'
      }),
      areaSize () {
        if (!this.feature) return
        if (this.feature.geometry.type !== 'Polygon') return

        const areaInSm = area(this.feature)
        const areaInHa = areaInSm / 10000 // 1 ha = 10000 sm
        const areaInSkm = areaInSm / 1000000 // 1 skm = 1000000 sm
        return {
          sm: (areaInSm).toFixed(4),
          ha: (areaInHa).toFixed(4),
          skm: (areaInSkm).toFixed(4)
        }
      },
      numberOfVertexes () {
        if (!this.feature) return
        let count = 0
        coordEach(this.feature.geometry, () => count++)
        return count
      },
      featureCode () {
        return this.$route.params.featureCode
      },
      projectName () {
        const id = this.feature.properties.project_id
        const name = this.projects.find(p => p.project_id === id)?.project_name
        return name ? name : id
      },
      iconName () {
        return this.feature.geometry.type.toLowerCase()
      },
      createdOrigin () {
        let created_origin = this.feature.properties.created_origin
        let created_at = this.feature.properties.created_at
        if (created_origin) {
          if (created_origin.toString().length === 10) {
            created_origin = created_origin * 1000
          }

          return formatTime(created_origin, this.settings.tz)
        } else {
          return formatTime(created_at, this.settings.tz)
        }
      },
      createdAt() {
        return formatTime(this.createdOrigin, this.settings.tz, `MMMM D YYYY, ${this.settings.timeFormat === '12' ? 'h:mm a' : 'H:mm'}`)
      },
      syncedAt() {
        return formatTime(this.feature.properties.created_at, this.settings.tz, `MMMM D YYYY, ${this.settings.timeFormat === '12' ? 'h:mm a' : 'H:mm'}`)
      },
      updatedAt() {
        return formatTime(this.feature.properties.updated_at, this.settings.tz, `MMMM D YYYY, ${this.settings.timeFormat === '12' ? 'h:mm a' : 'H:mm'}`)
      },
      resources() {
        const resources = this.feature.properties.resources
        const thumbnails = resources.filter(r => r.type === 'thumbnail')
        const fullsizes = resources.filter(r => r.type === 'fullsize')
        return {thumbnails, fullsizes}
      }
    },
    watch: {
      featureCode: {
        handler (code) {
          this.$store.dispatch('planet/clearLayers')
          this.fetchLayers()

          if (this.feature) {
            this.fetchGeofenceEvents()
          }
        },
        immediate: true
      },
      feature: {
        handler (feature) {
          if (feature) {
            this.fetchGeofenceEvents()

            if (this.map) {
              this.focusOnFeature()
            }
          }
        },
        immediate: true
      },
      map: {
        handler (map) {
          if (map && this.feature) {
            this.focusOnFeature()
          }
        },
        immediate: true
      }
    },
    methods: {
      copyUrl () {
        const url = this.$router.resolve({name: 'Feature', params: {project_id: this.feature.properties.project_id, featureCode: this.feature.properties.code}}).href
        const fullUrl = window.location.origin + url
        navigator.clipboard.writeText(fullUrl)
        toast({title: 'Share link copied to clipboard'})
      },
      focusOnFeature () {
        this.map.fitBounds(bbox(this.feature.geometry))
      },
      back (){
        this.$router.push({name: 'ProjectHome', params: {project_id: this.feature.properties.project_id}})
      },
      pairToProject () {
        this.$store.dispatch('geo/pairToProject', this.feature)
      },
      showLeftDialog () {
        this.$router.push({name: 'FeatureEdit', params: {featureCode: this.feature.properties.code}})
        this.focusOnFeature()
      },
      simplifyGeometry (geometry, tolerance = 0.0001) {
        let simplified = simplify(geometry, {
          tolerance: tolerance,
          highQuality: false,
        })

        const feature = this.feature
        feature.geometry = simplified

        this.$store.dispatch('geo/updateFeatureGeometry', feature)
      },
      exportGeojson () {
        const geoJSON = {
          type: 'FeatureCollection',
          features: [this.feature]
        }
        const fileName = this.feature.properties.name
        const geoJSONString = JSON.stringify(geoJSON, null, 2)
        const blob = new Blob([geoJSONString], { type: "application/json" })
        const link = document.createElement('a')

        link.href = URL.createObjectURL(blob)
        link.download = fileName + '.geojson'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
      async fetchGeofenceEvents() {
        try {
          const response = await API({
            method: 'GET',
            url: `${HOST}/geo/feature/${this.feature.properties.code}/geofence`
          })

          this.geofenceEvents = response.data.reverse()
        } catch (e) {
          console.log('Error fetching geofence events')
        }
      },
      deleteReport () {
        this.$store.dispatch('geo/deleteFeature', this.feature)
        this.$router.push({name: 'ProjectHome', params: {projectId: this.feature.properties.project_id}})
      },
      fetchLayers () {
        this.$store.dispatch('planet/fetchLayers', this.featureCode)
      }
    }
  }
</script>
