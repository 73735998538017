<script setup>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { ChevronLeft } from 'lucide-vue-next'

import {Button} from '@/components/ui/button'
import { FormFeature } from '@/components/forms'

const store = useStore()
const route = useRoute()
const router = useRouter()

const feature = computed(() => store.getters['geo/getCurrentFeature'])


const back = () => {
  router.push({name: 'Feature', params: {featureCode: feature.value.properties.code}})
}
</script>

<template>
  <div class="p-2">
    <div class="flex items-center justify-between">
      <Button size="sm" variant="ghost" class="w-8 h-8 rounded-full p-0" @click="back">
        <ChevronLeft class="text-gray-500" />
      </Button>
    </div>
    <div v-if="feature" class="mt-2">
      <FormFeature
        :editMode="true"
        :feature="feature"
        :key="feature"
        @update:saved="back"
      />
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div>
</template>
