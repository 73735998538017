<script setup >
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import { FolderDot, ChevronDown } from 'lucide-vue-next'
import {Button} from '@/components/ui/button'
import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import {ImagesList} from '@/components/ui/images'
import {Badge} from '@/components/ui/badge'

import {Point, LineString, Polygon} from '@/components/icons'

const isShowChilds = ref(false)

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])

const props = defineProps({
  feature: Object
})

// const toggleVisibility = (value) => {
//   const features = map.value.querySourceFeatures('reports_mixed', {
//     sourceLayer: 'report-lines',
//     filter: ['==', 'code', props.feature.properties.code]
//   })

//   features.forEach((feature) => {
//     console.log(feature.id)
//     // map.value.setLayoutProperty(feature.id, 'visibility', value ? 'visible' : 'none')
//   })

//   console.log(features)
//   // console.log(props.feature.properties.code, value)
// }

</script>

<template>
  <div class="border-b" :class="{'bg-gray-100': isCurrent }">
    <div style="overflow: hidden;" @click="setCurrent">
      <div class="px-3 py-3">
        <div class="flex items-center justify-between">
          <div @click="isDateToggle = !isDateToggle">
            <span v-if="isDateToggle" class="text-gray-500">{{ createdAtFromNow }}</span>
            <span v-else class="text-gray-500">{{ createdAtFormated }}</span>
          </div>

          <div class="flex items-center">
            <span class="mr-2 text-gray-500">{{ feature.properties.member_display_name }}</span>
            <Avatar class="w-5 h-5">
              <AvatarImage
                v-if="feature.properties.member_avatar_url"
                :src="feature.properties.member_avatar_url"
                :alt="feature.properties.member_display_name"
                :key="feature.properties.code"
              />
              <AvatarFallback></AvatarFallback>
            </Avatar>
          </div>
        </div>

        <div class="mt-3 flex">
          <div class="min-w-8 min-h-8 w-8 h-8 rounded-full shadow flex items-center justify-center" :style="{'background-color': feature.properties.color}">
            <div v-if="feature.properties.s3_icon">
              <img class="object-contain w-5 h-5" :src="feature.properties.s3_icon" alt="">
            </div>
            <div v-else>
              <Point v-if="iconName === 'point'" class="fill-white" />
              <LineString v-if="iconName === 'linestring'" class="fill-white" />
              <Polygon v-if="iconName === 'polygon'" class="fill-white" />
            </div>
          </div>

          <div class="ml-3 mt-1">
            <router-link :to="{name: 'Feature', params: {featureCode: feature.properties.code}}">
              <h3 class="text-base font-semibold hover:text-primary">{{ feature.properties.name }}</h3>
            </router-link>
          </div>
        </div>

        <div v-if="feature.properties.shared" class="mt-3">
          <Badge variant="secondary" class="text-xs">Shared</Badge>
        </div>
      </div>
    </div>

    <div v-if="feature.properties?.resources && resourcesLength" class="px-3 pb-3">
      <ImagesList :images="resources" />
    </div>

    <div v-if="feature.properties.childs_count > 0" class="px-3 pb-3">
      <Collapsible v-model:open="isShowChilds">
        <CollapsibleTrigger>
          <Button size="sm" variant="outline" @click="fetchFeatureChilds" class="flex items-center text-gray-600">
            {{ feature.properties.childs_count }} reports
            <ChevronDown class="ml-1 w-4 h-4 text-gray-400" />
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div class="border border-gray-200 mt-2 rounded-lg">
            <FeaturesListChild v-if="currentFeatureChilds" :features="currentFeatureChilds?.features" is-child :key="feature.properties.code" no-pagination />
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { API, HOST } from '@/utils/http'

import {formatTime, formatTimeFromNow} from '@/utils/datetime'
import {defineAsyncComponent} from 'vue'

export default {
  name: 'FeatureListItem',
  components: {
    FeaturesListChild: defineAsyncComponent(() => import("@/components/features/FeaturesList.vue"))
  },
  data() {
    return {
      viewerOptions: {
        url: 'src',
        zIndex: 9999999
      },
      maxGalleryImages: 3,
      isActive: false,
      isDateToggle: true,
      images: [],
      selected_face: null,
      currentFeatureChilds: null
    };
  },
  computed: {
    ...mapGetters({
      settings: 'user/getUserSettings',
      currentFeature: 'geo/getCurrentFeature',
      projects: 'project/getProjects'
    }),
    isCurrent () {
      if (!this.currentFeature) return false
      return this.currentFeature.properties.code === this.feature.properties.code
    },
    iconName () {
      const subtype = this.feature.properties.subtype ? this.feature.properties.subtype : 'report'
      const geometryType = this.feature.geometry.type.toLowerCase()
      return subtype === 'report' ? geometryType : subtype
    },
    resources() {
      let resources = this.feature.properties.resources
      const thumbnails = resources.filter(r => r.type === 'thumbnail')
      const fullsizes = resources.filter(r => r.type === 'fullsize')
      return {thumbnails, fullsizes}
    },
    resourcesLength () {
      return this.resources.thumbnails.length
    },
    createdAt () {
      let created_origin = this.feature.properties.created_origin
      if (!created_origin) {
        return this.feature.properties.created_at
      } else {
        if (created_origin.toString().length === 10) {
          created_origin = this.feature.properties.created_origin * 1000
        } else if (created_origin.toString().length === 13) {
          created_origin = this.feature.properties.created_origin
        }
        return created_origin
      }
    },
    createdAtFormated () {
      return formatTime(this.createdAt, this.settings.tz, 'D MMMM YYYY HH:mm')
    },
    createdAtFromNow() {
      return formatTimeFromNow(this.createdAt, this.settings.tz)
    }
  },
  methods: {
    async fetchFeatureChilds () {
      const response = await API({
        method: 'GET',
        url: `${HOST}/geo/feature/${this.feature.properties.code}/childs`
      })

      this.currentFeatureChilds = response.data
    },
    setProject (projectId) {
      const project = this.projects.find(p => p.project_id === projectId)
      this.$store.commit('project/SET_CURRENT', project)
    },
    setCurrent () {
      this.$store.commit('geo/SET_CURRENT_FEATURE', {
        feature: this.feature,
        zoom: 15
      })
    },
    isHappinessReport() {
      let is_happiness = false;

      for (let tag of this.feature.properties.tags) {
        if (tag.k === 'happiness_report') {
          is_happiness = true;
          this.selected_face = tag;
          return is_happiness;
        }
      }

      return is_happiness;
    },
    getHappinessImage() {
      return ''
      // return this.happines_map[this.selected_face.v];
    },
    isAvatarExists(member) {
      return Boolean(member.picture_url || member.avatar_url);
    }
  },
};
</script>

