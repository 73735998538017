<script setup>
import {computed, onBeforeUnmount, onMounted, watch, ref} from 'vue'
import {useStore} from 'vuex'
import {bbox} from '@turf/turf'

import {Trash2, Eye, FileWarning, Pencil, Save, Split} from 'lucide-vue-next'
import {Button} from '@/components/ui/button'
import {FormFeature} from '@/components/forms'

const geometryToDrawmode = {
  Point: 'draw_point',
  LineString: 'draw_line',
  Polygon: 'draw_polygon'
}

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])
const draw = computed(() => store.getters['geo/getDraw'])

const props = defineProps({
  feature: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})
const showForm = ref(props.index < 10)

const emit = defineEmits(['removeFeature', 'startEditing', 'stopEditing', 'updateFeature', 'split'])

const removeFeature = (feature) => {
  emit('removeFeature', feature)
}

const splitGeometryCollection = (feature) => {
  emit('split', feature)
}

const startDraw = () => {
  draw.value.changeMode(geometryToDrawmode['Point'])
  emit('startEditing', props.feature)
}

const focusFeature = (feature) => {
  const bounds = bbox(feature)
  map.value.fitBounds(bounds, { padding: 20 })
}

const stopEditing = () => {
  draw.value.deleteAll()
  emit('stopEditing', props.feature)
}

const startEditing = (feature) => {
  focusFeature(feature)

  draw.value.changeMode(geometryToDrawmode[feature.geometry.type])
  draw.value.set({
    type: 'FeatureCollection',
    features: [feature]
  })
  emit('startEditing', feature)
}

const onDrawCreate = async (e) => {
  if (!props.feature.properties.isEditing) return
  props.feature.geometry = e.features[0].geometry
}

const onDrawUpdate = async (e) => {
  if (!props.feature.properties.isEditing) return
  const data = draw.value.getAll()
  console.log(data)
  props.feature.geometry = data.features[0].geometry
}

const updateFeature = (feature) => {
  props.feature.properties = feature.properties
  emit('updateFeature', props.feature)
}

watch(() => props.feature.properties.isEditing, (isEditing) => {
  console.log('isEditing', isEditing)
  if (!isEditing) {
    stopEditing()
  }
})

onMounted(() => {
  map.value.on('draw.create', onDrawCreate)
  map.value.on('draw.update', onDrawUpdate)
})

onBeforeUnmount(() => {
  map.value.off('draw.create', onDrawCreate)
  map.value.off('draw.update', onDrawUpdate)

  if (props.feature.properties.isEditing) {
    stopEditing()
  }
})

</script>

<template>
<div
  :key="feature.properties.local_uuid"
  class="border border-gray-200 rounded-lg mb-2"
>
  <div class="p-2">
    <div class="flex items-start justify-between">
      <div class="pr-3">
        <h3 class="font-bold">Shape №{{ index + 1 }} <span v-if="feature.properties.isEditing" class="bg-yellow-300 px-1 rounded-sm">Editing</span></h3>
      </div>
      <div class="flex items-center space-x-1">
        <Button @click="removeFeature(feature)" size="sm" variant="ghost" class="w-7 h-7 p-0">
          <Trash2 class="text-red-500 w-4 h-4" />
        </Button>
        <Button v-if="feature.geometry" @click="focusFeature(feature)" size="sm" variant="outline">
          <Eye class="w-4 h-4" />
          <span class="ml-1">Preview</span>
        </Button>
      </div>
    </div>

    <div class="mt-2">
      <ul>
        <li>File: {{ feature.properties.fileName ? feature.properties.fileName : 'unknown file' }}</li>
        <li v-if="feature.geometry">Type: {{ feature.geometry.type }}</li>
      </ul>
    </div>


    <div class="mt-3">
      <Button
        v-if="feature.geometry && !feature.properties.isEditing"
        @click="() => startEditing(feature)"
        size="sm"
        variant="outline"
      >
        <div class="flex items-center">
          <Pencil class="w-4 h-4 text-gray-600" />
          <span class="ml-1">Edit Geometry</span>
        </div>
      </Button>

      <Button
        v-else-if="feature.geometry && feature.properties.isEditing"
        @click="() => stopEditing(feature)"
        size="sm"
      >
        <div class="flex items-center">
          <Save class="w-4 h-4 text-white" />
          <span class="ml-1">Save Geometry</span>
        </div>
      </Button>

      <div class="mt-2" v-if="feature.geometry && feature.geometry.type === 'GeometryCollection'">
        <Button
          size="sm"
          variant="outline"
          @click="() => splitGeometryCollection(feature)"
        >
          <div class="flex items-center">
            <Split class="w-4 h-4 text-gray-600" />
            <span class="ml-1">Split Geometry Collection</span>
          </div>
        </Button>
        <p class="text-gray-500 mt-1 text-xs">
          Geometry collection contains multiple geometries ({{ feature.geometry.geometries.length }}).
          You can split it into separate shapes and upload them individually.
        </p>
      </div>
    </div>

    <div v-if="!feature.geometry" class="border border-yellow-400 rounded px-3 py-2">
      <div class="flex items-center justify-between">
        <FileWarning class="text-yellow-500 w-5 h-5" />
        <span>Missing geometry. <br> Replace or draw a valid one.</span>
        <Button @click="startDraw" size="sm" variant="secondary">Draw</Button>
      </div>
    </div>

    <Button
      v-if="!showForm"
      @click="() => showForm = true"
      size="sm"
      variant="outline"
    >
      <div class="flex items-center">
        <Pencil class="w-4 h-4 text-gray-600" />
        <span class="ml-1">Edit Data and Upload</span>
      </div>
    </Button>
  </div>

  <div class="p-2 border-t border-gray-200">
    <FormFeature
      v-if="feature.geometry && showForm"
      :predefined="true"
      :feature="feature"
      hide-close
      @update:created="removeFeature"
      @update:feature="updateFeature"
    />
  </div>
</div>
</template>
