<script setup>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { FormProject } from '@/components/forms'

const store = useStore()
const route = useRoute()

const projectId = computed(() => parseInt(route.params.project_id))
const project = computed(() => store.getters['project/current'])

onBeforeMount(async () => {
  await store.dispatch('project/fetchProject', projectId.value)
})
</script>

<template>
  <div class="p-2">
    <div v-if="project">
      <FormProject :project="project" :key="project.project_id" :edit="true" />
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div>
</template>
