import { isEqual } from 'lodash'
import {secondsDiff} from '@/utils/datetime'

export const mutations = {
  SET_TOTAL_FEATURES(state, payload) {
    state.totalFeatures = payload
  },
  SET_CURRENT_FEATURE  (state, payload) {
    state.currentFeature = payload.feature
  },
  SET_CURRENT_FEATURES_CHILDS(state, payload) {
    state.currentFeatureChilds = payload
  },
  SET_CONTEXT_FORM_FEATURE(state, payload) {
    state.contextFormFeature = payload
  },

  CLEAR_SEARCH_SELECTED(state) {
    state.searchSelected = []
  },

  SET_MAP(state, payload) {
    state.map = payload
  },
  SET_MAP_READY(state, payload) {
    state.mapReady = payload
  },
  SET_DRAW(state, payload) {
    state.draw = payload
  },
  SET_MAIN_LAYER(state, payload) {
    state.mainLayer = payload
  },
  SET_PLANET_LAYER(state, payload) {
    state.planetLayer = payload
  },

  SET_FEATURES_LOADER(state, payload) {
    state.featuresLoader = payload
  },


  SET_BEACONS(state, payload) {
    state.beacons = payload
  },
  NEXT_BEACON(state, next_beacon) {
    if (!state.beacons || state.beacons.find(b => b.properties.member_id === next_beacon.properties.member_id)) {
      const index = state.beacons.findIndex(b => b.properties.member_id === next_beacon.properties.member_id)
      state.beacons.splice(index, 1, next_beacon)
    } else {
      state.beacons.push(next_beacon)
    }
  },
  REMOVE_BEACON(state, memberId) {
    const index = state.beacons.findIndex(beacon => beacon.properties.member_id === memberId)
    state.beacons.splice(index, 1)
  },
  CLEANUP_BEACONS(state) {
    state.beacons = state.beacons.filter(beacon => secondsDiff(new Date(), beacon.properties.rel_ts) <= 120)
    console.log('WS: cleanup beacons')
  },

  SET_NOTES(state, payload) {
    state.notes = payload
  },
  ADD_NOTE(state, payload) {
    state.notes.push(payload)
  },
  DELETE_NOTE(state, noteId) {
    state.notes = state.notes.filter(n => n.id !== noteId)
  },

  SET_LOCATION(state, location) {
    if(location === null) {
      state.userSettings.location.lat = null
      state.userSettings.location.lng = null
    } else {
      state.userSettings.location.lat = location.coords.latitude
      state.userSettings.location.lng = location.coords.longitude
    }
  },

  SET_TILES_TOKEN(state, payload) {
    state.tilesToken = payload
  },
  SET_TILES_SETTINGS (state, payload) {
    state.tilesSettings = payload
  },
  SET_LAYERS(state, payload) {
    state.layers = payload
  },

  SET_FILES (state, payload) {
    state.files = payload
  },

  SET_TOTAL_ONMAP(state, payload) {
    state.totalOnMap = payload
  },

  SET_MAP_READY(state, payload) {
    state.mapReady = payload
  },

  ADD_REPORT_TEMPLATE(state, payload) {
    state.reportTemplates.push(payload)
  },

  SET_REPORT_TEMPLATES(state, payload) {
    state.reportTemplates = payload
  },

  UPDATE_REPORT_TEMPLATE(state, payload) {
    const index = state.reportTemplates.findIndex(t => t.id === payload.id)
    state.reportTemplates.splice(index, 1, payload)
  },

  DELETE_REPORT_TEMPLATE(state, payload) {
    state.reportTemplates = state.reportTemplates.filter(t => t.id !== payload.id)
  },

  SET_FEATURE_CREATED(state, payload) {
    state.isFeatureCreated = payload
  }
}
