<script setup>
import { onMounted } from 'vue'
import {bbox} from '@turf/turf'
import {MapSource, MapLayer} from '@/components/map'

const props = defineProps({
  feature: Object,
  map: Object
})

onMounted(() => {
  if (props.feature) {
    props.map.fitBounds(bbox(props.feature), { padding: 50, duration: 600 })
  }
})
</script>

<template>
  <MapSource
    v-if="feature"
    :map="map"
    type="geojson"
    id="previewFeature"
    :data="{
      type: 'FeatureCollection',
      features: [feature]
    }"
    :options="{
      'generateId': true
    }"
  >
    <MapLayer
      :map="map"
      id="previewFeatureFill"
      type="fill"
      source="previewFeature"
      :options="{
        'paint': {
          'fill-color': '#059669',
          'fill-opacity': 0.1
        },
        'filter': ['==', '$type', 'Polygon']
      }"
    ></MapLayer>

    <MapLayer
      :map="map"
      id="previewFeatureLine"
      type="line"
      source="previewFeature"
      :options="{
        'paint': {
          'line-color': '#059669',
          'line-width': 3
        },
        'filter': ['==', '$type', 'Polygon']
      }"
    ></MapLayer>
  </MapSource>
</template>
