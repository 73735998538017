import * as z from 'zod'
import {isEqual} from 'lodash'
import {router} from '@/router'

function safeParseJSON(string) {
  try { return JSON.parse(string) }
  catch { return string }
}

function makeObjSchema(schema) {
  return z.preprocess(input => {
    const parsedObj = {}
    for (const [key, value] of Object.entries(input)) {
      parsedObj[key] = safeParseJSON(value)
    }
    return parsedObj
  }, schema)
}

const stringToArray = (value) => {
  if (value.includes(',')) {
    return value.split(',')
  } else {
    return [value]
  }
}

const arrayToString = (value) => {
  return value.join(',')
}

const stringToArrayOfNumbers = (value) => {
  return stringToArray(value).map(Number)
}

const arrayKeys = ['member_ids', 'tags']
const paramsSchema = z.object({
  member_ids: z.union([z.string(), z.number()]).transform(v => {
    return typeof v === 'string' ? stringToArrayOfNumbers(v) : [v]
  }).optional(),
  tags: z.union([z.string(), z.number()]).transform(v => {
    return typeof v === 'string' ? stringToArrayOfNumbers(v) : [v]
  }).optional(),
  shared: z.boolean().nullable().optional(),
  has_resources: z.boolean().nullable().optional(),
  text: z.union([z.string(), z.number()]).transform(v => v.toString()).optional(),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  center_lat: z.number().optional(),
  center_lng: z.number().optional(),
  zoom: z.number().optional(),
  south: z.number().optional(),
  north: z.number().optional(),
  east: z.number().optional(),
  west: z.number().optional(),
})

const searchObjSchema = makeObjSchema(paramsSchema)

const stateToParams = (state) => {
  const params = {}
  state.forEach(param => {
    params[param.key] = param.value.toString()
  })
  return params
}

export const mutations = {
  SET_PARAMS (state, params) {
    const currentParams = stateToParams(state.params)

    if (isEqual(params, currentParams)) return

    const validatedParams = searchObjSchema.safeParse(params)

    if (validatedParams.success === false) {
      console.log(validatedParams.error)
    } else {
      state.params = []

      Object.entries(validatedParams.data).forEach(([key, values]) => {
        const newParam = { key, value: values }
        state.params.push(newParam)
      })
    }
  },
  ADD_PARAM(state, { key, value }) {
    const urlParams = new URLSearchParams(window.location.search)
    const param = state.params.find(param => param.key === key)
    if (param) {
      if (Array.isArray(param.value)) {
        param.value.push(value)
        urlParams.set(key, arrayToString(param.value))
      } else {
        param.value = value
        urlParams.set(key, value)
      }
    } else {
      const isArray = arrayKeys.includes(key)
      state.params.push({ key, value: isArray ? [value] : value })
      urlParams.set(key, isArray ? value : arrayToString([value]))
    }

    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
  },
  REMOVE_PARAM (state, {key, value}) {
    const urlParams = new URLSearchParams(window.location.search)
    const param = state.params.find(param => param.key === key)

    if (!param) return

    if (Array.isArray(param.value)) {
      const index = param.value.indexOf(value)
      if (index > -1) {
        param.value.splice(index, 1)
        urlParams.set(key, arrayToString(param.value))

        if (param.value.length === 0) {
          urlParams.delete(key)
        }
      }
      // console.log('REMOVE_PARAM', key, 'array', value)
    } else {
      // console.log('REMOVE_PARAM', key, value)
      state.params = state.params.filter(param => param.key !== key)
      urlParams.delete(key)
    }
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
  },
  RESET_PARAMS (state) {
    console.log('RESET_PARAMS')
    state.params = []
    window.history.replaceState({}, '', window.location.pathname)
  }
}
