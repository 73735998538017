<script setup>
import { onMounted, onUnmounted } from 'vue'

const emit = defineEmits(['click'])
const props = defineProps({
  map: {
    type: Object,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  source: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
    validator: (value) =>
      ['fill', 'line', 'symbol', 'circle', 'heatmap', 'fill-extrusion', 'raster', 'hillshade', 'background', 'sky'].includes(value),
  },
  options: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  image: {
    type: String,
    required: false,
  },
})

const addLayerListeners = (layer) => {
  props.map.on('mousemove', layer, (e) => {
    props.map.getCanvas().style.cursor = 'pointer'
  })

  props.map.on('mouseleave', layer, () => {
    props.map.getCanvas().style.cursor = 'default'
  })

  props.map.on('click', layer, (e) => {
    emit('click', e)
  })
}

const removeLayerListeners = (layer) => {
  props.map.off('mousemove', layer)
  props.map.off('mouseleave', layer)
  props.map.off('click', layer)
}

const addLayer = async () => {
  if (props.image) {
    props.map.loadImage(props.image, (error, image) => {
      if (error) {
        console.error(error)
        return
      }
      props.map.addImage(props.id, image)
      if (!props.map.getLayer(props.id)) {
        props.map.addLayer({
          id: props.id,
          source: props.source,
          type: props.type,
          ...props.options,
        })
        addLayerListeners(props.id)
      } else {
        console.warn(`Layer '${props.id}' already exists on the map.`)
      }
    })
  } else {
    if (!props.map.getLayer(props.id)) {
      props.map.addLayer({
        id: props.id,
        source: props.source,
        type: props.type,
        ...props.options,
      })
      addLayerListeners(props.id)
    } else {
      console.warn(`Layer '${props.id}' already exists on the map.`)
    }
  }
}

const removeLayer = () => {
  if (props.map.getLayer(props.id)) {
    if (props.image) {
      props.map.removeImage(props.id)
    }
    props.map.removeLayer(props.id)
    removeLayerListeners(props.id)
  }
}

onMounted(async () => {
  await addLayer()
})

onUnmounted(() => {
  removeLayer()
})
</script>

<template>
  <slot></slot>
</template>
