<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import { API, HOST } from '@/utils/http'
import { centroid, bbox } from '@turf/turf'

import { MapSource, MapLayer, MapImageMarker } from '@/components/map'
import { Spinner } from '@/components/ui/spinner'

const router = useRouter()
const route = useRoute()
const store = useStore()

const isLoggedIn = computed(() => store.getters.getIsLoggedIn)
const beacons = computed(() => store.getters['geo/getBeacons'])
const params = computed(() => store.getters['search/getParams'])
const mapParamsKeys = ['zoom', 'center_lat', 'center_lng', 'south', 'north', 'east', 'west']

const pointsData = ref([])
const pointsWithResources = ref([])
const mixedData = ref([])

const filterParams = computed(() => {
  return params.value.filter((param) => !mapParamsKeys.includes(param.key))
})

const props = defineProps({
  map: {
    type: Object,
    required: true,
  },
  project: {
    type: Object,
    required: true,
  },
})

const isLoadingData = ref(false)
const routeProjectId = computed(() => parseInt(route.params.project_id))
const featurePages = ['Feature', 'FeatureIndex', 'FeatureEdit']
const isFeaturePage = featurePages.includes(route.name)
const routeFeatureCode = computed(() => isFeaturePage ? route.params.featureCode : null)

const isCurrentProject = computed(() => {
    const routeProjectId = Number(route.params.project_id);
    return !isNaN(routeProjectId) && props.project.project_id === routeProjectId;
})
const featureCode = computed(() => props.project.paired_feature_code)
const feature = ref(null)
const featureCenter = computed(() => {
  if (!feature.value) return null
  return centroid(feature.value)
})
const beaconMarkers = ref([])
const featuresBoundary = ref(null)


const getProjectBoundary = async (featureCode) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/geo/feature/${featureCode}`
    })

    return response.data
  } catch (e) {
    throw new Error(e)
  }
}

const updateBounds = async () => {
  props.map.setPadding({ top: 0, bottom: 0, left: 0, right: 0 })

  const bounds = props.map.getBounds()
  const center = props.map.getCenter()

  const options = {
    center_lat: center.lat,
    center_lng: center.lng,
    zoom: props.map.getZoom(),
    south: bounds.getSouth(),
    north: bounds.getNorth(),
    east: bounds.getEast(),
    west: bounds.getWest()
  }

  for (const [key, value] of Object.entries(options)) {
    store.commit('search/ADD_PARAM', {
      key: key,
      value: value
    })
  }

  localStorage.setItem('mapOptions', JSON.stringify({
    center: props.map.getCenter(),
    zoom: props.map.getZoom()
  }))

  await loadData()
}


const updateInProgress = ref(false)

const handleUpdateBounds = async () => {
  if (updateInProgress.value) return
  updateInProgress.value = true
  await updateBounds()
  updateInProgress.value = false
}

const onLayerClick = (feature) => {
  const bounds = bbox(feature)
  props.map.fitBounds(bounds, { padding: 50 })

  if (feature.properties.paired_project_id) {
    router.push({ name: 'Project', params: { project_id: props.project.project_id } })
  } else {
    router.push({ name: 'Feature', params: { project_id: props.project.project_id, featureCode: feature.properties.code } })
  }
}

const fetchData = async () => {
  try {
    const paramsMap = params.value.reduce((acc, param) => {
      acc[param.key] = param.value
      return acc
    }, {})

    const filterParamsMap = filterParams.value.reduce((acc, param) => {
      acc[param.key] = param.value
      return acc
    }, {})

    const payload = {
      shared: filterParamsMap['shared'],
      has_resources: filterParamsMap['has_resources'],
      start_date: filterParamsMap['start_date'],
      end_date: filterParamsMap['end_date'],
      is_meta: false,

      // map params
      south: paramsMap['south'],
      north: paramsMap['north'],
      east: paramsMap['east'],
      west: paramsMap['west'],
    }

    const response = await API({
      method: 'GET',
      url: `${HOST}/geo/features/${routeProjectId.value}`,
      params: payload
    })

    store.commit('geo/SET_TOTAL_ONMAP', response.data?.total)

    return response.data
  } catch (e) {
    if (e.response?.status === 404) {
      toast({title: `Project data not found`})
    } else {
      throw new Error(e)
    }
  }
}

const setFeatures = (features) => {
  pointsData.value = features.features.filter((feature) =>
    feature.geometry.type === 'Point' &&
    feature.properties.code !== routeFeatureCode.value &&
    !feature.properties.resources?.length
  )
  pointsWithResources.value = features.features.filter((feature) =>
    feature.geometry.type === 'Point' &&
    feature.properties.resources &&
    feature.properties.resources.length
  )

  mixedData.value = features.features.filter((feature) =>
    feature.geometry.type !== 'Point' &&
    feature.properties.code !== routeFeatureCode.value
  )
}

const loadData = async () => {
  if (!routeProjectId.value || !isLoggedIn.value || !isCurrentProject.value) return

  isLoadingData.value = true

  const { features, features_boundary } = await fetchData()
  featuresBoundary.value = features_boundary
  if (!features) {
    isLoadingData.value = false
    return
  }
  setFeatures(features)

  isLoadingData.value = false
}

onMounted(async () => {
  if (featureCode.value) {
    feature.value = await getProjectBoundary(featureCode.value)
  }

  await loadData()

  props.map.on('dragend', async () => {
    await handleUpdateBounds()
  })

  props.map.on('zoomend', async () => {
    await handleUpdateBounds()
  })
})

watch(
  () => filterParams.value,
  async (newValue, oldValue) => {
    if (!oldValue) return

    await loadData()
  },
  { deep: true, immediate: true }
)
</script>

<template>
  <div v-if="isCurrentProject">
    <div v-if="isLoadingData || updateInProgress" class="absolute z-50 top-14 bg-white rounded-full p-2 shadow-lg" style="left: 50%">
      <Spinner class="w-6 h-6" />
    </div>

    <div>
      <div v-if="beacons.length" :key="beacons.length">
        <MapImageMarker
          v-for="feature in beacons"
          ping
          :label="feature.properties.member_display_name"
          :url="feature.properties.member_avatar_url"
          :feature="feature"
          :map="map"
          @click="(e) => onLayerClick(feature)"
        ></MapImageMarker>
      </div>

      <div :key="pointsWithResources.length">
        <MapImageMarker
          v-for="feature in pointsWithResources"
          :feature="feature"
          :url="feature.properties.resources[0].url"
          :map="map"
          @click="(e) => onLayerClick(feature)"
        ></MapImageMarker>
      </div>

      <!-- Mixed geometry -->
      <MapSource
        :key="mixedData.length"
        :map="map"
        type="geojson"
        :id="'project-' + project.project_id + '-reports_mixed'"
        :data="{
          type: 'FeatureCollection',
          features: mixedData
        }"
        :options="{
          'generateId': true
        }"
      >
        <MapLayer
          :map="map"
          :id="'project-' + project.project_id + '-report-fill'"
          :source="'project-' + project.project_id + '-reports_mixed'"
          type="fill"
          :options="{
            paint: {
              'fill-color': ['get', 'color'],
              'fill-opacity': 0.02
            },
            filter: ['==', '$type', 'Polygon']
          }"
          @click="(e) => onLayerClick(e.features[0])"
        ></MapLayer>
        <MapLayer
          :map="map"
          :id="'project-' + project.project_id + '-report-lines'"
          :source="'project-' + project.project_id + '-reports_mixed'"
          type="line"
          :options="{
            paint: {
              'line-color': ['get', 'color'],
              'line-width': 3
            },
            filter: ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']]
          }"
          @click="(e) => onLayerClick(e.features[0])"
        ></MapLayer>

        <!-- Points -->
        <MapSource
          :key="pointsData.length"
          :map="map"
          type="geojson"
          :id="'project-' + project.project_id + '-reports_points'"
          :data="{
            type: 'FeatureCollection',
            features: pointsData
          }"
          :options="{
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50
          }"
        >
          <MapLayer
            :map="map"
            :id="'project-' + project.project_id + '-clusters'"
            :source="'project-' + project.project_id + '-reports_points'"
            type="circle"
            :options="{
              paint: {
                'circle-color': ['step', ['get', 'point_count'], '#2259ff', 100, '#2259ff', 750, '#2259ff'],
                'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
                'circle-stroke-width': 2,
                'circle-stroke-color': '#fff'
              },
              filter: ['has', 'point_count']
            }"
            @click="(e) => onLayerClick(e.features[0])"
          ></MapLayer>
          <MapLayer
            :map="map"
            :id="'project-' + project.project_id + '-cluster-count'"
            :source="'project-' + project.project_id + '-reports_points'"
            type="symbol"
            :options="{
              layout: {
                'text-field': ['get', 'point_count_abbreviated'],
                'text-size': 18
              },
              paint: {
                'text-color': '#fff'
              },
              filter: ['has', 'point_count_abbreviated']
            }"
            @click="(e) => onLayerClick(e.features[0])"
          ></MapLayer>
          <MapLayer
            :map="map"
            :id="'project-' + project.project_id + '-unclustered-point'"
            :source="'project-' + project.project_id + '-reports_points'"
            type="circle"
            :options="{
              paint: {
                'circle-color': ['get', 'color'],
                'circle-radius': 8,
                'circle-stroke-width': 2,
                'circle-stroke-color': '#fff'
              },
              filter: ['!', ['has', 'point_count']]
            }"
            @click="(e) => onLayerClick(e.features[0])"
          ></MapLayer>
        </MapSource>
      </MapSource>
    </div>

    <!-- Beacons -->
    <MapSource
      :key="beacons.length"
      :map="map"
      type="geojson"
      :id="'project-' + project.project_id + '-beacons'"
      :data="{
        type: 'FeatureCollection',
        features: beaconMarkers
      }"
      :options="{
        'generateId': true
      }"
    >
      <MapLayer
        :map="map"
        :id="'project-' + project.project_id + '-beacons'"
        :source="'project-' + project.project_id + '-beacons'"
        type="circle"
        :options="{
          paint: {
            'circle-color': '#000000',
            'circle-radius': 20,
            'circle-stroke-width': 4,
            'circle-stroke-color': '#ffffff'
          }
        }"
        @click="(e) => onLayerClick(e.features[0])"
      ></MapLayer>
    </MapSource>
  </div>

  <!-- Boundaries -->

  <div v-if="feature && !isCurrentProject">
    <MapSource
      :map="map"
      type="geojson"
      :id="'project-' + project.project_id"
      :data="feature"
    >
      <MapLayer
        :map="map"
        :id="'project-' + project.project_id + '-fill'"
        :source="'project-' + project.project_id"
        type="fill"
        :options="{
          paint: {
            'fill-color': 'black',
            'fill-opacity': 0.1
          },
          filter: ['==', '$type', 'Polygon']
        }"
        @click="(e) => onLayerClick(e.features[0])"
      />
      <MapLayer
        :map="map"
        :id="'project-' + project.project_id + '-lines'"
        :source="'project-' + project.project_id"
        type="line"
        :options="{
          paint: {
            'line-color': 'black',
            'line-width': 1
          },
          filter: ['any', ['==', '$type', 'Polygon']]
        }"
        @click="(e) => onLayerClick(e.features[0])"
      />

      <MapSource
        v-if="featureCenter"
        :map="map"
        type="geojson"
        :id="'project-' + project.project_id + '-center'"
        :data="featureCenter"
      >
        <MapLayer
          :map="map"
          :id="'project-' + project.project_id + '-center'"
          :source="'project-' + project.project_id + '-center'"
          type="symbol"
          :options="{
            layout: {
              'text-field': project.project_name,
              'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
              'text-radial-offset': 0.5,
              'text-justify': 'auto',
              'text-size': 16,
              'text-font': ['Open Sans Bold']
            },
            'paint': {
              'text-color': 'white',
              'text-halo-color': 'black',
              'text-halo-width': 1
            }
          }"
          @click="(e) => onLayerClick(e.features[0])"
        />
      </MapSource>
    </MapSource>
  </div>
</template>
