<script setup>
import {computed, onMounted, watch, onBeforeMount} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'

import {ChevronLeft, Settings, Users} from 'lucide-vue-next'
import {Button} from '@/components/ui/button'
import {Label} from '@/components/ui/label'
import { useCheckOrgAccess } from '@/composables/useCheckAccess'

const store = useStore()
const route = useRoute()
const orgId = computed(() => parseInt(route.params.org_id))
const org = computed(() => store.getters['org/getCurrent'])

onBeforeMount(() => {
  store.dispatch('org/fetchOrg', orgId.value)
})

watch(orgId, (newOrgId) => {
  store.dispatch('org/fetchOrg', newOrgId)
})
</script>

<template>
  <div v-if="org" :key="org.organization_id" class="flex items-center justify-between px-3 pt-3">
    <div class="basis-1/2 flex items-center">
      <div v-if="org">
        <Label class="text-xs h-3 text-gray-500">Organization Settings</Label>
        <h2 class="text-lg font-bold">{{org.organization_name}}</h2>
      </div>
    </div>
    <div class="basis-1/2 flex items-center justify-end space-x-2">
      <router-link :to="{ name: 'OrgMembers', params: { org_id: orgId }} ">
        <Button size="sm" :variant="route.name === 'OrgMembers' ? 'default' : 'secondary' " class="h-8">
          <Users class="w-4 h-4 mr-2" />
          Members
        </Button>
      </router-link>

      <router-link :to="{ name: 'OrgSettings', params: { org_id: orgId }} " v-if="useCheckOrgAccess(org, 'org', ['update'])">
        <Button size="sm" :variant="route.name === 'OrgSettings' ? 'default' : 'secondary' " class="h-8">
          <Settings class="w-4 h-4 mr-2" />
          Settings
        </Button>
      </router-link>
    </div>
  </div>
  <div v-if="org" :key="org.organization_id" class="p-3">
    <router-view></router-view>
  </div>
</template>
