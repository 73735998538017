import { mapReady } from "./actions";

export const state = {
  map: null,
  draw: null,
  mapReady: false,
  mainLayer: null,
  planetLayer: null,
  totalFeatures: 0,

  featuresLoader: false,

  currentFeature: null,
  currentFeatureChilds: null,
  contextFormFeature: null,

  page: 1,
  perPage: 10,

  planetFeaturesEnabled: false,
  beacons: [],

  notes: [],

  tilesToken: null,
  tilesSettings: null,
  tilesMosaics: [],

  files: null,
  layers: null,
  mapReady: false,
  reportTemplates: null,

  isFeatureCreated: false,
  totalOnMap: 0,
}
