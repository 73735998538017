<script setup>
import { computed, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { FormProject } from '@/components/forms'

const store = useStore()
const route = useRoute()

const projectId = computed(() => parseInt(route.params.project_id))
const project = computed(() => store.getters['project/current'])
const members = computed(() => store.getters['project/getMembers'])

onBeforeMount(async () => {
  await store.dispatch('project/fetchProject', projectId.value)
  await store.dispatch('project/fetchMembers', projectId.value)
})

watch(() => projectId.value, async (value) => {
  await store.dispatch('project/fetchMembers', value)
})
</script>

<template>
  <div class="p-2">
    <div v-if="project && members" :key="members">
      <FormProject :project="project" :key="project.project_id" :members="members" />
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div>
</template>
