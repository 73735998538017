<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  map: {
    type: Object,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
    validator: (value) =>
      ['geojson', 'vector', 'raster', 'image', 'video'].includes(value),
  },
  data: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  options: {
    type: Object,
    required: false,
    default: () => ({}),
  },
})

const isAdded = ref(false)

const addSource = () => {
  if (!props.map.getSource(props.id)) {
    props.map.addSource(props.id, {
      type: props.type,
      data: props.data,
      ...props.options,
    })
    isAdded.value = true
  } else {
    console.warn(`Source '${props.id}' already exists on the map.`)
  }
}

const removeSource = () => {
  if (props.map.getSource(props.id)) {
    props.map.removeSource(props.id)
  }
}

onMounted(() => {
  addSource()
})

onUnmounted(() => {
  removeSource()
})
</script>

<template>
  <slot v-if="isAdded"></slot>
</template>

