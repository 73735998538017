<script setup lang="ts">
import {ref, computed} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {Button} from '@/components/ui/button'
import { X } from 'lucide-vue-next'

const router = useRouter()
const route = useRoute()

const isBig = computed(() => route.meta.layout === 'float-big')
const isShow = ref(true)

router.afterEach(() => {
  isShow.value = true
})

</script>

<template>
  <div
    v-if="route.meta.layout !== 'hide' && isShow"
    class="flex-1 text-sm relative"
    :class="{'min-w-[700px] max-w-[700px]': isBig, 'min-w-[340px] max-w-[340px]': !isBig}"
  >
    <div class="p-1 flex items-center justify-between sticky top-0 bg-white shadow z-10">
      <div class="font-bold">
        {{ route.meta.title }}
      </div>
      <Button
        @click="isShow = false"
        variant="ghost"
        class="w-6 h-6 p-0 rounded-full"
      >
        <X class="w-5 h-5" />
      </Button>
    </div>
    <div class="flex-1 h-full overflow-y-auto">
      <router-view />
    </div>
  </div>
</template>
