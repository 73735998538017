<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import mapboxgl from 'mapbox-gl'

const emit = defineEmits(['click'])
const props = defineProps({
  map: {
    type: Object,
    required: true,
  },
  feature: {
    type: Object,
    required: true,
  },
  url: {
    type: String,
    required: true
  },
  ping: {
    type: Boolean,
    required: false,
    default: false,
  },
  label: {
    type: String,
    required: false,
    default: null,
  },
})

const marker = ref(null)
const markerWrap = ref(null)
const coordinates = computed(() => {
  return props.feature.geometry.coordinates
})

onMounted(() => {
  if (!coordinates.value) return
  marker.value = new mapboxgl.Marker({
    anchor: 'bottom',
    element: markerWrap.value,
  }).setLngLat(coordinates.value).addTo(props.map)
})

onBeforeUnmount(() => {
  markerWrap.value.remove()
})
</script>

<template>
  <div ref="markerWrap" @click="() => emit('click')" class="flex flex-col items-center justify-center">
    <div class="relative w-9 h-9 border border-gray-400 rounded-full">
      <div v-if="ping" class="animate-ping bg-primary absolute top-0 left-0 inline-flex h-full w-full rounded-full opacity-75 z-10"></div>
      <div :style="{ backgroundImage: `url(${url})` }" class="h-full w-full origin-center rounded-full bg-white border-2 border-white shadow-lg cursor-pointer bg-contain z-20"></div>
    </div>
    <div v-if="label" class="text-xs whitespace-nowrap backdrop-blur-sm bg-white/40 text-center mt-1 rounded px-1 py-0.5 shadow-lg">{{label}}</div>
  </div>
</template>
