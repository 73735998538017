<script setup>
// Notes:
// 1. onMounted initializing mapbox
// 2. on mapbox load creating sources and layers, adding listeners
// 3. on mapbox load initializing watchEffect for update map on filters data change
// 4. on mapbox dragend and zoomend updating map data

import { ref, watch, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import {useMouse} from '@vueuse/core'

import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { bbox, area, centroid, length } from '@turf/turf'

import { DialogCreateFeature } from '@/components/dialogs'
import { DialogCreate, MapProjectLayer } from '@/components/map'

mapboxgl.accessToken = 'pk.eyJ1IjoiZWFydGhwYmMiLCJhIjoiY2x1dGRiMGU4MDdrdjJsb2ducG5pbG41ayJ9.XiX9hVsMSiOXSPf78V3dag'

const { x, y } = useMouse()

const router = useRouter()
const route = useRoute()
const projectId = computed(() => parseInt(route.params.project_id))

const store = useStore()
const isLoggedIn = computed(() => store.getters.getIsLoggedIn)
const layersToken = computed(() => store.getters['geo/getTilesToken'])
const layers = computed(() => store.getters['geo/getLayers'])
const mapReady = computed(() => store.getters['geo/getMapReady'])
const projects = computed(() => store.getters['project/getProjects'])
const flatedProjects = computed(() => store.getters['project/getFlatedProjects'])

const projectsToRender = computed(() => {
  if (!projectId.value) {
    return projects.value
  } else {
    const currentProject = flatedProjects.value.find((project) => project.project_id === projectId.value)

    if (!currentProject) return null

    if (currentProject?.children?.length > 0) {
      return [...currentProject.children, currentProject]
    } else {
      return [currentProject]
    }
  }
})

const params = computed(() => store.getters['search/getParams'])

const isShowDetails = ref(false)
const hoveredFeature = ref(null)

const createFeatureDialog = ref(null)
const mapParams = computed(() => {
  const zoom = params.value.find((param) => param.key === 'zoom')
  const centerLat = params.value.find((param) => param.key === 'center_lat')
  const centerLng = params.value.find((param) => param.key === 'center_lng')

  return zoom && centerLat && centerLng ? {
    center: [centerLng.value, centerLat.value],
    zoom: zoom.value
  } : null
})

const localMapOptions = JSON.parse(localStorage.getItem('mapOptions'))

const map = ref(null)
const draw = ref(null)
const currentDrawMode = ref('none')
const drawedData = ref(null)

const defaultOptions = {
  center: [-91.874, 42.76],
  zoom: 4
}

const protocol = location.protocol === 'https:' ? 'https' : 'http'
const host = `${protocol}://${window.location.host}`
const baseSources = {
  'osm-tiles-source': {
    'type': 'raster',
    'tiles': ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
    'tileSize': 256,
    'attribution':
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  },
  'osmtopo-tiles-source': {
    'type': 'raster',
    'tiles': ['https://tile.opentopomap.org/{z}/{x}/{y}.png'],
    'tileSize': 256,
    'attribution':
      'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
      '<a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">' +
      'OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
  }
}

const baseLayers = [
  // default mapbox layer
  {
    'id': 'osm-tiles-layer',
    'type': 'raster',
    'source': 'osm-tiles-source',
    'minzoom': 0,
    'maxzoom': 18
  },
  {
    'id': 'osmtopo-tiles-layer',
    'type': 'raster',
    'source': 'osmtopo-tiles-source',
    'minzoom': 0,
    'maxzoom': 18,
    'layout': {
      'visibility': 'none'
    }
  }
]


const createMap = async () => {
  if (isLoggedIn.value) {
    await store.dispatch('geo/fetchLayersToken')
    await store.dispatch('geo/fetchLayers')
  }

  const planetSources = {}
  const planetLayers = []

  // set basemap tiles
  layers.value?.globals.forEach(layer => {
    planetSources[`planet-${layer.name}-source`] = {
      'type': 'raster',
      'tiles': [`${host}/tulum/tiles/1/${layer.name}/{z}/{x}/{y}.png?token=${layersToken.value}`],
      'tileSize': 256,
      'attribution': '&copy; <a href="https://www.planet.com/">Planet</a>'
    }

    planetLayers.push({
      'id': `planet-${layer.name}-layer`,
      'type': 'raster',
      'source': `planet-${layer.name}-source`,
      'minzoom': 0,
      'maxzoom': 18,
      'layout': {
        'visibility': 'none'
      }
    })
  })

  const style = {
    'glyphs': 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    'version': 8,
    'sources': {
      ...baseSources,
      ...planetSources
    },
    'layers': [
      ...baseLayers,
      ...planetLayers
    ]
  }

  map.value = new mapboxgl.Map({
    container: 'mapDiv',
    style: style,
    minZoom: 1,
    maxZoom: 17,
    // merge options from local storage with default
    ...mapParams.value ? mapParams.value : localMapOptions ? localMapOptions : defaultOptions
  })

  draw.value = new MapboxDraw({
    displayControlsDefault: false,
    styles: [
      {
        'id': 'highlight-active-points',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'circle-radius': 12,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff',
        }
      },
      {
        'id': 'highlight-active-points-outlinebox',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'circle-radius': 18,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], 'rgba(0, 0, 0, 0)'],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#000000',
        }
      },
      {
        'id': 'points-are-blue',
        'type': 'circle',
        'filter': ['all',
          ['==', '$type', 'Point'],
          ['==', 'meta', 'feature'],
          ['==', 'active', 'false']
        ],
        'paint': {
          'circle-radius': 10,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        }
      },
      // ACTIVE (being drawn)
      // line stroke
      {
        'id': 'gl-draw-line',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'LineString'],
          ['==', 'active', 'true']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-dasharray': [0.2, 2],
          'line-width': 4
        }
      },
      // polygon fill
      {
        'id': 'gl-draw-polygon-fill',
        'type': 'fill',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'fill-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-outline-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-opacity': 0.1
        }
      },
      // polygon outline stroke
      {
        'id': 'gl-draw-polygon-stroke-active',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'true']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-dasharray': [0.2, 2],
          'line-width': 4
        }
      },
      // vertex points
      {
        'id': 'gl-draw-points-active',
        'type': 'circle',
        'filter': ['all',
          ['==', 'meta', 'vertex'],
          ['==', '$type', 'Point'],
          ['==', 'active', 'true']
        ],
        'paint': {
          'circle-radius': 8,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        }
      },
      // INACTIVE (static, already drawn)
      // line stroke
      {
        'id': 'gl-draw-line-static',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'LineString'],
          ['==', 'active', 'false']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-width': 2
        }
      },
      // polygon fill
      {
        'id': 'gl-draw-polygon-fill-static',
        'type': 'fill',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'false']
        ],
        'paint': {
          'fill-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-outline-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'fill-opacity': 0.1
        }
      },
      // polygon outline
      {
        'id': 'gl-draw-polygon-stroke-static',
        'type': 'line',
        'filter': ['all',
          ['==', '$type', 'Polygon'],
          ['==', 'active', 'false']
        ],
        'layout': {
          'line-cap': 'round',
          'line-join': 'round'
        },
        'paint': {
          'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'line-width': 2
        }
      },
      // vertex points
      {
        'id': 'gl-draw-points-static',
        'type': 'circle',
        'filter': ['all',
          ['==', 'meta', 'vertex'],
          ['==', '$type', 'Point'],
          ['==', 'active', 'false']
        ],
        'paint': {
          'circle-radius': 7,
          'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff'
        }
      }
    ]
  });

  map.value.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
  map.value.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    }), 'bottom-right')
    map.value.addControl(draw.value, 'bottom-right')

  store.commit('geo/SET_MAP', map.value)
  store.commit('geo/SET_DRAW', draw.value)
}

const startDraw = (type) => {
  draw.value.changeMode(type)
}

const resetDrawing = () => {
  draw.value.deleteAll()
  drawedData.value = []
}

const onDrawUpdate = async (e) => {
  const data = draw.value.getAll()
  drawedData.value = []

  for (const [index, feature] of data.features.entries()) {
    let areaText = ''

    if (feature.geometry.type === 'Polygon') {
      const areaInSm = area(feature)
      const areaInHa = (areaInSm / 10000).toFixed(3) // 1 ha = 10000 sm
      const areaInSkm = (areaInSm / 1000000).toFixed(4) // 1 skm = 1000000 sm
      areaText = `${areaInHa} ha (${areaInSkm} km²)`

      feature.properties = {
        ...feature.properties,
        areaInSm: areaInSm,
        areaInHa: areaInHa,
        areaInSkm: areaInSkm,
        areaText: areaText
      }
    }

    if (feature.geometry.type === 'LineString') {
      const lengthInM = length(feature, { units: 'meters' })
      const lengthInKm = (lengthInM / 1000).toFixed(2)
      areaText = `${lengthInKm} km`

      feature.properties = {
        ...feature.properties,
        lengthInM: lengthInM,
        lengthInKm: lengthInKm,
        areaText: areaText
      }
    }

    const areaCenter = centroid(feature)
    const sourceId = 'areaLabelSource-' + feature.id
    const layerId = 'areaLabel-' + feature.id

    feature.properties = {
      ...feature.properties,
      areaCenter: areaCenter
    }

    drawedData.value.push(feature)

    if (map.value.getSource(sourceId)) {
      map.value.getSource(sourceId).setData(areaCenter)
      map.value.setLayoutProperty(layerId, 'text-field', areaText)
    } else {
      map.value.addSource(sourceId, {
        type: 'geojson',
        data: areaCenter
      })

      map.value.addLayer({
        id: layerId,
        type: 'symbol',
        source: sourceId,
        layout: {
          'text-field': areaText,
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-size': 13
        }
      })
    }
  }
}


const deleteFeature = async (feature) => {
  if (!feature.id) return

  draw.value.delete(feature.id)
  cleanUpFeature(feature)
  drawedData.value = drawedData.value.filter((item) => item.id !== feature.id)
}

const cleanUpFeature = async (feature) => {
  const sourceId = 'areaLabelSource-' + feature.id
  const layerId = 'areaLabel-' + feature.id
  if (map.value.getSource(sourceId)) {
    map.value.removeLayer(layerId)
    map.value.removeSource(sourceId)
  }
}

const onDrawDelete = async (e) => {
  for (const feature of e.features) {
    cleanUpFeature(feature)
  }
}

const focusOnFeature = (feature) => {
  const bounds = bbox(feature)
  map.value.fitBounds(bounds, { padding: 50, duration: 600 })
}

const previewFeature = (feature) => {
  if (map.value.getLayer('preview-polygon-fill')) {
    map.value.removeLayer('preview-polygon-fill')
  }

  if (map.value.getLayer('preview-polygon-lines')) {
    map.value.removeLayer('preview-polygon-lines')
  }

  if (map.value.getSource('preview-polygon')) {
    map.value.removeSource('preview-polygon')
  }

  if (!feature) return

  if (!map.value.getSource('preview-polygon')) {
    map.value.addSource('preview-polygon', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [feature]
      },
      'generateId': true
    })
  }

  if (!map.value.getLayer('preview-polygon-fill')) {
    map.value.addLayer({
      'id': 'preview-polygon-fill',
      'type': 'fill',
      'source': 'preview-polygon',
      'paint': {
        'fill-color': '#059669',
        'fill-opacity': 0.1
      },
      'filter': ['==', '$type', 'Polygon']
    })
  }

  if (!map.value.getLayer('preview-polygon-lines')) {
    map.value.addLayer({
      'id': 'preview-polygon-lines',
      'type': 'line',
      'source': 'preview-polygon',
      'paint': {
        'line-color': '#059669',
        'line-width': 3
      },
      'filter': ['any', ['==', '$type', 'Polygon']]
    })
  }

  const bounds = bbox(feature)
  map.value.fitBounds(bounds, { padding: 50, duration: 600 })
}

const onCreated = async (feature) => {
  deleteFeature(feature)
  await store.commit('geo/SET_FEATURE_CREATED', true)
}


onMounted(async () => {
  await createMap()

  map.value.on('load', async () => {
    await store.dispatch('geo/mapReady', true)
  })

  // DRAWING
  map.value.on('draw.create', onDrawUpdate)
  map.value.on('draw.update', onDrawUpdate)
  map.value.on('draw.delete', onDrawDelete)

  map.value.on('mousemove', (e) => () => {
    if (!draw.value.getMode()) return
    if (currentDrawMode.value === draw.value.getMode()) return
    currentDrawMode.value = draw.value.getMode()
  })
})

watch(() => drawedData.value, async (value) => {
  store.commit('geo/SET_CONTEXT_FORM_FEATURE', value[0])
})
</script>

<template>
  <MapProjectLayer
    v-if="map && mapReady && projectsToRender && projectsToRender.length"
    v-for="project in projectsToRender"
    :map="map"
    :key="project.project_id"
    :project="project">
  </MapProjectLayer>

  <div v-if="currentDrawMode.includes('draw')" :style="{ top: `${y+10}px`, left: `${x+10}px` }" class="fixed bg-white rounded shadow-lg px-1 py-0.5 z-50">
    <span>Click to create or update a geometry</span>
  </div>

  <!-- <div v-if="isShowDetails" :style="{ top: `${y+10}px`, left: `${x+10}px` }" class="fixed bg-white rounded shadow-lg px-1 py-0.5 z-50">
    {{ hoveredFeature?.properties?.name }}
  </div> -->

  <div class="relative min-h-full w-full h-full bg-slate-100 overflow-hidden">
    <div id="mapDiv" class="map w-full h-full"></div>
    <DialogCreateFeature v-if="projects.length" ref="createFeatureDialog" @update:created="(e) => onCreated(e)" />

    <!-- <div class="bg-white absolute z-50 top-0 left-0 w-[300px] py-1 px-2 m-1 rounded shadow-lg max-h-[600px] overflow-y-auto">
      <h4>Debug</h4>
      <div v-if="beacons?.length">({{beacons?.length}}) {{ beacons }}</div>
      <div>{{ params }}</div>
    </div> -->

    <div class="absolute z-50 bottom-5 right-10 py-2 px-3" :class="{'mt-9': !!projectId}">
      <div class="flex items-end justify-end">
        <DialogCreate
          :drawedFeatures="drawedData?.length > 0 ? drawedData : []"
          @startDraw="(type) => startDraw(type)"
          @deleteFeature="(feature) => deleteFeature(feature)"
          @focusOnFeature="(feature) => focusOnFeature(feature)"
          @previewFeature="(feature) => previewFeature(feature)"
          @resetDrawing="() => resetDrawing()"
        />
      </div>
    </div>
  </div>
</template>

<style>
.user-beacon {
  background-size: cover;
  background-position: center;
}

.slideRight-enter-active, .slideRight-leave-active {
  transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
}
.slideRight-enter-from, .slideRight-leave-to {
  transform: translateX(-3%);
  opacity: 0;
}
</style>
