<script setup>
import {computed, ref, watch, onBeforeUnmount} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import {debounce} from 'lodash'
import axios from 'axios'
import {Trash2, X, Eye} from 'lucide-vue-next'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select"
import { Spinner } from '@/components/ui/spinner'
import { MemberAvatar } from '@/components/ui/avatar'

import {DialogConfirm} from '@/components/dialogs'
import { useCheckProjectAccess } from '@/composables/useCheckAccess'
import {MapFeaturePreview} from '@/components/map'

const router = useRouter()

const props = defineProps({
  project: {
    type: Object,
    default: null
  },
  members: {
    type: Array,
    default: [],
    optional: true
  },
  edit: {
    type: Boolean,
    default: false
  }
})

const isRegion = ref(props.edit ? !!props.project.place_details : false)

const previewGeometry = ref(null)
const selectedGeometry = ref(null)
const isSearching = ref(false)
const searchText = ref('')
const searchFeatures = ref([])

const store = useStore()
const isMapReady = computed(() => store.getters['geo/getMapReady'])
const map = computed(() => store.getters['geo/getMap'])
const orgs = computed(() => store.getters['org/getOrgs'])
const flatedProjects = computed(() => store.getters['project/getFlatedProjects'])

const member = computed(() => store.getters['user/getMember'])

const formMembers = computed(() => {
  return props.members.map(m => {
    return {
      is_checked: m.role_name === 'project_admin',
      project_member_id: m.project_member_id,
      role_name: m.role_name,
      member_id: m.member_id,
      display_name: m.display_name,
      avatar_url: m.avatar_url,
      email: m.email
    }
  })
})

const workflowChoices = [
  { label: 'Default', value: 'Default' },
  { label: 'Fisher', value: 'Fisher' },
  { label: 'Worker Welfare', value: 'Worker Welfare' }
]

const isoToEmoji = (isoCode) => {
  const codePoints = isoCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

const defaultParentId = !props.edit ? props.project.project_id.toString() : props.project.parent_id ? props.project.parent_id.toString() : ''

const schema = ref(z.object({
  name: z.string().min(3).max(255).default(props.edit ? props.project.project_name : ''),
  description: z.string().max(255).nullable().optional().default(props.edit ? props.project.project_desc : ''),
  orgId: z.string().default(props.project ? props.project.organization_id.toString() : null),
  parentId: z.string().optional().default(defaultParentId),
  country: z.string().nullable().default(null),
  workflow: z.string().optional().default(props.edit ? props.project.ui : 'Default'),
  placeDetails: z.object({
    place_id: z.number(),
    country_code: z.string(),
    localname: z.string(),
  }).optional().nullable().default(props.edit ? props.project.place_details : null),
  members: z.array(z.object({
    is_checked: z.boolean(),
    project_member_id: z.number(),
    email: z.string(),
    role_name: z.string(),
    member_id: z.number(),
    display_name: z.string(),
    avatar_url: z.string(),
  })).default(formMembers.value)
}))

const onSubmit = async (values) => {
  if (!props.edit) {
    const newProjectId = await store.dispatch('project/createProjectRequest', {
      parentId: values.parentId,
      name: values.name,
      description: values.description,
      orgId: values.orgId,
      country: values.country,
      ui: values.workflow,
      placeDetails: values.placeDetails,
      geojson: selectedGeometry.value ? selectedGeometry.value.geometry : null,
      member: member.value
    })

    const newProjectMembers = values.members.filter(i => i.is_checked && i.member_id !== member.value.member_id)
    for (const member of newProjectMembers) {
      await store.dispatch('project/addInvite', {
        member_id: member.member_id,
        project_id: newProjectId,
        role_name: member.role_name,
        name: member.display_name,
        email: member.email,
        manage_members: true
      })
    }
  } else {
    await store.dispatch('project/updateProject', {
      project_id: props.project.project_id,
      parentId: values.parentId,
      name: values.name,
      description: values.description,
      orgId: values.orgId,
      country: values.country,
      ui: values.workflow,
      placeDetails: values.placeDetails,
      geojson: selectedGeometry.value ? selectedGeometry.value.geometry : null,
      member: member.value
    })
  }

  await store.dispatch('project/fetchProjects')
  router.push({ name: 'Dashboard' })
}


const selectGeometry = async (feature, setValuesFunc) => {
  if (!feature) {
    selectedGeometry.value = null
    return
  }
  selectedGeometry.value = feature


  const response = await axios.get('https://nominatim.openstreetmap.org/details.php', {
    params: {
      place_id: feature.properties.placeId,
      addressdetails: 1,
      hierarchy: 0,
      group_hierarchy: 1,
      format: 'json'
    }
  })

  const details = response.data

  selectedGeometry.value.properties.country_code = details.country_code

  setValuesFunc({
    name: props.edit ? props.project.project_name : details.localname,
    placeDetails: {
      place_id: details.place_id,
      country_code: details.country_code,
      localname: details.localname,
    }
  })
}

const resetSearch = () => {
  searchFeatures.value = []
  selectedGeometry.value = null
  previewGeometry.value = null
}

const searchLocation = async (value) => {
  resetSearch()
  try {
    const response = await axios.get('https://nominatim.openstreetmap.org/search', {
      params: {
        q: value,
        polygon_geojson: 1,
        format: 'json',
        limit: 6
      }
    })

    const results = response.data

    if (results.length === 0) {
      return
    }

    for (const result of results) {
      const feature = {
        type: 'Feature',
        geometry: result.geojson,
        properties: {
          isSearch: true,
          name: result.display_name || result.name,
          addresstype: result.addresstype,
          placeId: result.place_id,
        }
      }

      searchFeatures.value.push(feature)
    }
  } catch (error) {
    console.error(error)
  }
}

const showFeature = (feature) => {
  previewGeometry.value = feature
}

const search = debounce(async () => {
  isSearching.value = true
  await searchLocation(searchText.value)
  isSearching.value = false
}, 1000)

const deleteProject = async () => {
  await store.dispatch('project/deleteProject', props.project)
  await store.dispatch('project/deactivateProjectForm')
  await store.dispatch('project/fetchProjects')

  router.push({ name: 'Dashboard' })
}

onBeforeUnmount(() => {
  resetSearch()
})

watch(() => searchText.value, async (value) => {
  if (searchText.value.length === 0) {
    resetSearch()
    return
  }

  await search()
})

watch(() => props.project, (value) => {
  schema.value = schema.value.extend({
    orgId: z.string().default(value.organization_id.toString()),
    parentId: z.string().optional().default(value.project_id.toString()),
  })
}, { deep: true })
</script>

<template>
  <MapFeaturePreview
    :key="previewGeometry"
    v-if="previewGeometry && isMapReady"
    :feature="previewGeometry"
    :map="map"
  />

  <Form
    v-if="project"
    :key="project.project_id ? project.project_id : null"
    class="space-y-3"
    v-slot="{ setValues }"
    :validation-schema="toTypedSchema(schema)"
    @submit="onSubmit"
  >
  <FormField v-if="project" v-slot="{ value, componentField }" name="parentId">
    <FormItem>
      <FormLabel>Parent Project</FormLabel>
      <FormControl>
        <Select v-bind="componentField">
          <SelectTrigger>
            <SelectValue placeholder="Select a project" />
          </SelectTrigger>
          <SelectContent class="max-h-[300px]">
            <SelectGroup>
              <SelectItem
                v-for="(p, index) of flatedProjects.filter(i => props.edit && i.project_id !== project.project_id || i.organization_id === project.organization_id)"
                :key="p.project_id"
                :value="`${p.project_id}`"
              >
                {{p.project_name}}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <FormField v-if="project" v-slot="{ value, componentField }" name="orgId">
    <FormItem>
      <FormLabel>Organization</FormLabel>
      <FormControl>
        <span class="text-gray-500">
          {{ orgs.find(i => i.organization_id === project.organization_id)?.organization_name }}
        </span>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <div>
    <div class="flex items-center">
      <Switch @update:checked="() => isRegion = !isRegion" :checked="isRegion" />
      <Label class="ml-2">Add Location</Label>
    </div>
    <div v-if="isRegion" class="mt-2">
      <h3 class="mb-1">Location</h3>
      <div class="relative">
        <Input
          placeholder="Search for country, city or place"
          v-model="searchText"
        />
        <Button
          v-if="searchText.length > 0 && !isSearching"
          variant="ghost"
          class="p-0 w-6 h-6 absolute top-2 right-2"
          @click="searchText = ''"
        >
          <X class="w-4 h-4 text-gray-500" />
        </Button>
        <Spinner v-if="isSearching" class="w-4 h-4 absolute top-3 right-3" />
      </div>

      <FormField v-if="project" v-slot="{ value, componentField }" name="placeDetails">
        <FormItem>
          <FormControl>
            <div v-if="searchFeatures.length > 0" class="my-2">
              <div class="bg-white border border-gray-200 rounded-md overflow-hidden">
                <div
                  v-for="(feature, index) in searchFeatures"
                  :key="feature.id"
                  class=" border-gray-200 border-b last:border-b-0 cursor-pointer"
                  :class="{'bg-secondary': selectedGeometry === feature}"
                >
                  <div class="flex justify-between">
                    <div @click="selectGeometry(feature, setValues)" class="w-full px-2 py-1">
                      <div class="flex items-center justify-between">
                        <h4 class="text-sm text-gray-600 mb-0.5" :class="{'font-semibold' : selectedGeometry === feature}">
                          <span v-if="!feature.properties.name">Drawed ({{ index + 1 }})</span>
                          <span v-else>
                            {{ feature.properties.name }}
                          </span>
                        </h4>
                      </div>
                      <ul class=" text-gray-400 text-xs">
                        <li v-if="feature.properties.addresstype" class="capitalize">Type: {{ feature.properties.addresstype }}</li>
                        <li>Geometry: {{ feature.geometry.type }}</li>
                        <li v-if="feature.properties.areaText">Size: {{ feature.properties.areaText }}</li>
                        <li>{{ feature.properties.placeId }}</li>
                      </ul>
                    </div>
                    <div class="flex p-1">
                      <Button size="sm" variant="ghost" class="w-6 h-6 p-1" @click="showFeature(feature)" type="button">
                        <Eye class="text-gray-600" />
                      </Button>
                      <Button v-if="!feature.properties.isSearch" @click="deleteFeature(feature)" size="sm" variant="ghost" class="w-6 h-6 p-1" type="button">
                        <Trash2 class="text-red-400" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>

      <div v-if="project.place_details && edit" class="mt-2">
        <h3 class="mb-1">Current Location</h3>
        <div class="bg-white border border-gray-200 rounded-md p-2">
          <h4 class="text-sm text-gray-600 mb-0.5">
            {{ project.place_details.localname }}
          </h4>
          <ul class=" text-gray-400 text-xs">
            <li>Country: {{ isoToEmoji(project.place_details.country_code) }}</li>
            <li>Place ID: {{ project.place_details.place_id }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <FormField v-slot="{ componentField }" name="name">
    <FormItem>
      <FormLabel>Name</FormLabel>
      <FormControl>
        <Input v-bind="componentField" />
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <FormField v-slot="{ componentField }" name="description">
    <FormItem>
      <FormLabel>Description <span class="text-gray-500">(Optional)</span></FormLabel>
      <FormControl>
        <Input v-bind="componentField" />
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <FormField v-slot="{ componentField }" name="workflow">
    <FormItem>
      <FormLabel>Workflow</FormLabel>
      <FormControl>
        <Select v-bind="componentField">
          <SelectTrigger>
            <SelectValue placeholder="Select a workflow" />
          </SelectTrigger>
          <SelectContent class="max-h-[300px]">
            <SelectGroup>
              <SelectItem
                v-for="(w, index) of workflowChoices"
                :key="index"
                :value="w.value"
              >
                {{w.label}}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <FormField v-slot="{ value, componentField }" name="members">
    <FormItem>
      <FormLabel>Members</FormLabel>
      <FormControl>
        <div v-for="m of value" class="flex items-center">
          <Checkbox
            :id="`${m.project_member_id}`"
            :checked="m.is_checked"
            :disabled="m.member_id === member.member_id"
            @update:checked="v => m.is_checked = v"
            class="mr-2"
          />
          <label :for="`${m.project_member_id}`" class="flex items-center">
            <MemberAvatar :avatar_url="m.avatar_url" />
            <div class="ml-2 flex flex-col">
              <span>{{ m.display_name }}</span>
              <span class="text-gray-500">{{ m.email }}</span>
            </div>
          </label>
        </div>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <div class="flex items-center justify-between">
    <div v-if="props.edit && useCheckProjectAccess(props.project, 'project', ['delete'])">
      <DialogConfirm
        @confirm="deleteProject"
        :title="'Delete ' + project.project_name"
        description="Are you sure you want to delete this project?"
      >
        <Button type="button" variant="destructive">Delete</Button>
      </DialogConfirm>
    </div>
    <div></div>
    <Button type="submit">{{ edit ? 'Save' : 'Create' }}</Button>
  </div>
</Form>
</template>
