<script setup>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import {ChevronRight, Folder, FolderPlus, Settings, Pencil, Eye} from 'lucide-vue-next'
import {bbox} from '@turf/turf'
import {API, HOST} from '@/utils/http'

import {Button} from '@/components/ui/button'

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])

const router = useRouter()
const route = useRoute()
const emit = defineEmits(['select'])
const props = defineProps({
  project: Object,
  openedProjectIds: Object
})

const routeProjectId = computed(() => parseInt(route.params.project_id))

const isSelected = computed(() => routeProjectId.value === props.project.project_id)
const isOpened = computed(() => !!props.openedProjectIds?.[props.project.project_id])
const isHovered = ref(false)

const select = (project, level = 0) => {
  emit('select', project, level)
}

const openProject = (project) => {
  router.push({ name: 'ProjectHome', params: { project_id: project.project_id } })
}


const isoToEmoji = (isoCode) => {
  const codePoints = isoCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

const createProject = () => {
  router.push({ name: 'ProjectCreate', params: { project_id: props.project.project_id }})
}

const editProject = () => {
  router.push({ name: 'ProjectEdit', params: { project_id: props.project.project_id } })
}

const zoomOnBoundary = async () => {
  const boundary = await store.dispatch('geo/fetchProjectBoundary', props.project.project_id)
  if (!boundary) return
  map.value.fitBounds(bbox(boundary))
}
</script>

<template>
  <div class="flex items-center px-0.5">
    <div
      class="flex items-center flex-1 w-full rounded-lg p-0.5 cursor-pointer"
      :class="{'bg-secondary': isHovered && !isSelected, 'bg-primary text-white': isSelected}"
      :value="project"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
      @click="openProject(project)"
    >
      <Button
        variant="ghostDark"
        class="w-7 h-7 rounded-full p-0 text-gray-400 hover:text-black"
        :class="{'opacity-0': !project.children.length, 'rotate-90 text-black': isOpened, 'text-white': isSelected}"
        @click.stop
        @click="select(project)"
      >
        <ChevronRight class="w-7 h-5" />
      </Button>
      <div class="flex items-center justify-between w-full min-w-[200px]">
        <div
          class="flex items-center h-full p-0.5 cursor-pointer"
          :class="{'truncate': isHovered}"
        >
          <div class="w-4 h-4 mr-2 flex items-center justify-center">
            <span v-if="project.place_details">
              {{ isoToEmoji(project.place_details.country_code) }}
            </span>
            <Folder class="w-4 h-4 text-gray-700" :class="{'text-white': isSelected}" />
          </div>
          <span class="truncate" :title="project.project_name">
            {{ project.project_name }}
          </span>
        </div>

        <div class="flex items-center justify-end" :class="{'opacity-0': !isHovered}" @click.stop>
          <Button variant="ghostDark" class="w-7 h-7 rounded-full p-0 text-gray-500 hover:text-black" :class="{'text-white': isSelected}" @click="zoomOnBoundary">
            <Eye class="w-4 h-4" />
          </Button>
          <Button variant="ghostDark" class="w-7 h-7 rounded-full p-0 text-gray-500 hover:text-black" :class="{'text-white': isSelected}" @click="createProject">
            <FolderPlus class="w-4 h-4" />
          </Button>
          <Button variant="ghostDark" class="w-7 h-7 rounded-full p-0 text-gray-500 hover:text-black" :class="{'text-white': isSelected}" @click="editProject">
            <Pencil class="w-4 h-4" />
          </Button>
          <Button variant="ghostDark" class="w-7 h-7 rounded-full p-0 text-gray-500 hover:text-black" :class="{'text-white': isSelected}" @click="router.push({ name: 'ProjectMembers', params: { project_id: project.project_id } })">
            <Settings class="w-4 h-4" />
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div class="pl-5 pr-1" v-if="isOpened">
    <ProjectListItem
      v-for="child in project.children"
      :key="child.project_id"
      :project="child"
      :opened-project-ids="openedProjectIds"
      @select="(project, level) => select(project, level + 1)"
    />

  </div>
</template>
